.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.posts-text {
  margin-left: 15px;
}

.header-text {
  margin-left: 35px;
}

.App-link {
  color: #61dafb;
}

.post {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  min-height: 30vh;
  min-width: 80vw;
  margin-bottom: 2%;
  padding: 1px 20px 1px 20px;
  border-radius: 10px;
}

.post:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.post_title {
  text-align: left;
}

.post_content {
  text-align: left;
}

.posts {
  margin-left: auto;
  margin-right: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
